// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["dependent"]

  // initialize() {
  //   console.log('toggle controller initialize')
  // }

  connect() {
    // console.log('toggle controller connect')

  } // connect

  toggle(event) {
      // console.log('toggle controller toggle')

      // debugger

      event.preventDefault()

      this.dependentTargets.forEach((el) => {

        // console.log(el.dataset.showIf, event.currentTarget.value, el.dataset.showIf == event.currentTarget.value)

        if ( el.classList.contains('hidden') ) {
          el.classList.remove('hidden')
        } else {
          el.classList.add('hidden')
        }

      })

    } // toggle

  disconnect() {
    // console.log('toggle controller disconnect')
  }


}