
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["label", "select"]

  connect() {
    this.set_timezone()
  }

  set_timezone() {

    const tz_matches = {
      cities: ["America/Los_Angeles", "America/San_Francisco"], tz: "Pacific Time (US & Canada)"
    }

    const client_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var matched_timezone;

    if ( tz_matches.cities.includes(client_timezone) ) {
      matched_timezone = tz_matches.tz
    } else {
      matched_timezone = client_timezone
    }

    if (this.selectTarget.value == "") {
      this.selectTarget.value = matched_timezone
    }

    console.log('attempting to set timezone to ', matched_timezone, ' updated to: ', this.selectTarget.value)
  }

}