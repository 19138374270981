import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "switch" ]

  connect() {
    // this.outputTarget.textContent = 'Hello, Stimulus!'

    console.log('form controller loaded')
    console.log(this.switchTarget)
  }

  handleChange() {
    console.log(this.switchTarget)
  }
}
