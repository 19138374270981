// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction


import { Controller } from "stimulus"
import Behave from "behave-js"

export default class extends Controller {
  static targets = [ "editor" ]


  connect() {

      this.$behaveEditor = new Behave({
          textarea: this.editorTarget,
          tabSize: 2,
      });

    console.log('yaml-editor connected')
  }

  disconnect() {
    this.$behaveEditor.destroy();
    console.log('yaml-editor unloaded')
  }
}
