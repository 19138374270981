// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "container", "subject", "body", "message", "link"]

  connect() {
    console.log("message-preview controller connected", this.subjectTarget)
    // console.log(get_meta_value("csrf-token"))
    // console.log("url", this.data.get('url'))

  }

  show(event) {
    event.preventDefault()
    event.stopImmediatePropagation()
    console.log("showing", event.target)
    this.messageTarget.innerHTML = ""
    this.get_preview()


  }

  hide(event) {
    event.preventDefault()
    event.stopImmediatePropagation()
    console.log("hiding", event.target)
    this.containerTarget.classList.add('hidden')
  }

  disable(event) {
    event.preventDefault()
    event.stopImmediatePropagation()
  }

  // get_meta_value(name) {
  //   const element = document.head.querySelector(`meta[name="${name}"]`)
  //   return element.getAttribute("content")
  // }

  get_preview() {

    function get_meta_value(name) {
      const element = document.head.querySelector(`meta[name="${name}"]`)
      return element.getAttribute("content")
    }

    const csrf_token = get_meta_value('csrf-token')
    const url = this.data.get('url')
    console.log("get_preview", url)
    const formData = new FormData()
    formData.append('subject', this.subjectTarget.value)
    formData.append('body', this.bodyTarget.value)

    console.log("formData:", formData)

    fetch(url, {
            method: "post",
            body: formData,
            credentials: "same-origin",
            headers: {
              "X-CSRF-Token": csrf_token
            }})
          .then(response => response.text())
          .then(html => {
            this.messageTarget.innerHTML = html
            this.containerTarget.classList.remove('hidden')
            this.add_link_warnings()
          })
  }

  add_link_warnings() {
    this.linkTargets.forEach( link => { this.add_link_warning(link) } )
    console.log('adding link warnings')
  }

  add_link_warning(link) {
    if (link.getAttribute('href') == "") {
      link.innerHTML = link.innerHTML + '<span style="color: red;">&lt;--Bad link! Fix {{value}} before sending!</span>'
      link.title = "Bad link! Please go back and make sure you're using the correct {{ values }}."
      link.setAttribute('data-action', 'click->message-preview#disable')
    } else {
      link.title = "This looks good. Click to test."
    }

    console.log('adding link warning')
  }


}