// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction


import { Controller } from "stimulus"

export default class extends Controller {
  // static targets = [ "output" ]

  connect() {
    var options = {valueNames: [ 'name1', 'name2', 'meeting-status', 'matching-style' ] };
    var myList = new List('list-container', options);
    console.log('meeting-list-controller loaded')
  }

  disconnect() {
    // myList = undefined;
    // options = undefined;
    console.log('meeting-list-controller unloaded')
  }
}
