import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["dependent", "sourceChecked", "sourceSelected", "sourceValue"]
  static values = { showIf: String }

  connect() {
    // console.log('dependent-form-items connect')

    // hide everything
    this.dependentTargets.forEach( (el) => el.classList.add('hidden') )

    // determine which selects, radios and checkboxes are selected, show dependent targets
    // show checked
    this.sourceCheckedTargets.forEach( (st) => {
      if (st.checked) {
        this.dependentTargets.forEach((el) => {
          if (el.dataset.showIf == st.value) { el.classList.remove('hidden') }
        })
      }
    })

    // show selected
    this.sourceSelectedTargets.forEach( (st) => {
        this.dependentTargets.forEach((el) => {
          if ( el.dataset.showIf.split(' ').includes(st.value) ) { el.classList.remove('hidden') }
        })
    })

    // debugger

    // show by value
    this.sourceValueTargets.forEach( (st) => {
        this.dependentTargets.forEach((el) => {
          if (el.dataset.showIf.split(' ').includes(st.value)) { el.classList.remove('hidden') }
        })
    })

  } // connect

  toggle() {

      this.dependentTargets.forEach((el) => {

        if ( el.dataset.showIf.split(' ').includes(event.currentTarget.value) ) {
          el.classList.remove('hidden')
        } else {
          el.classList.add('hidden')
        }

      })
    } // toggle

    toggleCheck() {

      this.dependentTargets.forEach((el) => {

        // possible to access el.dataset.showIf
        // not possible to access el.showIf
        if ( el.dataset.showIf.split(' ').includes(event.currentTarget.value) && event.currentTarget.checked ) {
          el.classList.remove('hidden')
        } else {
          el.classList.add('hidden')
        }
      })

    } // toggleCheck

  disconnect() {
    console.log('dependent-form-itmes disconnect')
  }


}





// import { Controller } from "stimulus"

// export default class extends Controller {
//   static targets = ["dependent", "sourceChecked", "sourceSelected", "dirtyData", "sourceValue"]

//   // initialize() {
//   //   console.log('dependent-form-itmes initialize')
//   // }

//   connect() {
//     console.log('dependent-form-items connect')

//     // use sessionStorage.setItem('key', 'value');
//     // https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage

//     // determine which selects, radios and checkboxes are selected, and activate their dependent targets

//       // hide everything
//       this.dependentTargets.forEach( (el) => el.classList.add('hidden') )

//       // show checked
//       this.sourceCheckedTargets.forEach( (st) => {
//         if (st.checked) {
//           this.dependentTargets.forEach((el) => {
//             if (el.dataset.showIf == st.value) { el.classList.remove('hidden') }
//           })
//         }
//       })

//       // show selected
//       this.sourceSelectedTargets.forEach( (st) => {
//           this.dependentTargets.forEach((el) => {
//             if ( el.dataset.showIf.split(' ').includes(st.value) ) { el.classList.remove('hidden') }
//             // if (el.dataset.showIf == st.value) { el.classList.remove('hidden') }
//           })
//       })

//       // debugger

//       // show by value
//       this.sourceValueTargets.forEach( (st) => {
//           this.dependentTargets.forEach((el) => {
//             // if (el.dataset.showIf == st.value) { el.classList.remove('hidden') }
//             if (el.dataset.showIf.split(' ').includes(st.value)) { el.classList.remove('hidden') }
//           })
//       })

//       // debugger


//   } // connect

//   toggle() {
//       console.log('dependent-form-items toggle')

//       // debugger
//       this.dirtyDataTarget.value = event.currentTarget.value
//       console.log(this.dirtyDataTarget.value)


//       this.dependentTargets.forEach((el) => {

//         // console.log(el.dataset.showIf, event.currentTarget.value, el.dataset.showIf == event.currentTarget.value)

//         if ( el.dataset.showIf.split(' ').includes(event.currentTarget.value) ) {
//           el.classList.remove('hidden')
//         } else {
//           el.classList.add('hidden')
//         }

//       })

//     } // toggle

//   disconnect() {
//     console.log('dependent-form-itmes disconnect')
//   }


// }