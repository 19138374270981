import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["item"]

  connect() {
    console.log(this.itemTarget)

    if ( document.visibilityState == 'visible') {
      this.itemTarget.click()
    }
  }

}