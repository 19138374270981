import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["field", "display"]

  connect() {
    console.log(this.fieldTarget)
    this.update()
  }

  update() {
    console.log(this.fieldTarget.value)
    const default_domain = 'onetimes.one'
    const via_domain = 'via onetimes.one'

    if (this.fieldTarget.value == '') {
      this.displayTarget.innerHTML = default_domain
    } else {
      this.displayTarget.innerHTML = this.fieldTarget.value + " " + via_domain
    }


  }

}