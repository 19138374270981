// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction


import { Controller } from "stimulus"
import EasyMDE from "easymde"

export default class extends Controller {
  static targets = [ "markdown" ]

  connect() {
    this.$simplemde = new EasyMDE({ element: document.getElementById(this.markdownTarget.id), forceSync: true });
    console.log('mardown-editor-controller loaded', this.markdownTarget.id)
  }

  disconnect() {
    // debugger
    // this.$simplemde.toTextArea()
    this.$simplemde = null
    // console.log('mardown-editor-controller unloaded')
  }
}
