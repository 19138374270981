import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["tab", "nav"]
  // static values = { showIf: String }

  connect() {
    console.log('tabs')
  } // connect

  toggle(event) {

    const tab_to_activate = event.target.dataset.activate

    this.navTargets.forEach((el) => {
      if (event.target == el) {
        el.classList.add('active')
      } else {
        el.classList.remove('active')
      }
    })

    this.tabTargets.forEach((el) => {
      if (el.dataset.tabname.split(' ').includes(tab_to_activate)) {
        el.classList.remove('hidden')
      } else {
        el.classList.add('hidden')
      }
    })

    event.preventDefault()

    // debugger

  }


  disconnect() {
    console.log('tabs disconnect')
  }


}
