// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["source"]

  connect() {
    // console.log('hi from clipboard')
    // console.log(this.sourceTarget)
  }

  copy() {
    console.log('hi from clipboard COPY')
    this.sourceTarget.select()
    document.execCommand("copy")

    // console.log(this.sourceTarget.value)
    // event.stopPropagation();
    // event.preventDefault();

  }

}